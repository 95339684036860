ul.pagination {
	list-style-type: none;
	margin: 1em 0 0 0;
	padding: 30px 0 0 0;
	border-top:1px solid #e1e1e1;

	li {
		display:inline-block;
		width:32.5%;
		color:#ccc;
	}

	li.page_number {
		text-align: center;
		color:#000;
	}

	li.next {
		text-align: right;
	}
}