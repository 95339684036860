footer {
	background-color: $brand-color;

	.wrapper {
		padding: 3em 0;
	}

	h2 {
		color: #fff !important;
	}

	a {
		color: #f1f1f1 !important;
	}

	p {
		color: $grey-color-light;
	}
	
	.pgp {
		margin-top:10px
	}

	.footer-col-1 {
		width: -webkit-calc(30% - (#{$spacing-unit} / 2));
		width:         calc(30% - (#{$spacing-unit} / 2));
	}

	.footer-col-3 {
		width: -webkit-calc(30% - (#{$spacing-unit} / 2));
		width:         calc(30% - (#{$spacing-unit} / 2));
	}
}

@media screen and (max-width: 40em) {
	.wrapper {
		padding: 20px !important;
	}
	.footer-col-1, .footer-col-2, .footer-col-3 {
		width: 100% !important;
	}
}
