.new-post {
  background-color: #f4f5f6;

  .post-header {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    .overlay {
      background-color: rgba(4, 0, 25, .6);
      padding: 6em 0;
      text-align: center;
      
      h1 {
        color: #ffffff;
        font-size: 3em;
      }

      @media screen and (max-width: 40em) {
        h1 {
          font-size: 2em;
        }
      }
      
      p {
        color: #fff;
        font-weight: 300 !important;
      }
    }

    // post_thumbnail_overlay_class: 
    .overlay-20 { background-color: rgba(4, 0, 25, .2); }
    .overlay-30 { background-color: rgba(4, 0, 25, .3); }
    .overlay-40 { background-color: rgba(4, 0, 25, .4); }
  }

  .post-wrapper {
    margin-top: -100px;

    .columns {
      padding-top: 10px;
      background-color: #fff;
      box-shadow: (0, 0, 0, 0.05) 0px 14px 26px 0px;

      h2, h3, h4, h5, h6 {
        color: rgb(42, 47, 54)
      }

      h2 {
        font-size: 2em;
      }

      h3 {
        font-size: 1.4em;
        font-weight: 400 !important;
      }

      .post-content {
        padding-top: 3em;

        p, li {
          font-size: 1.1em;
          line-height: 1.75em;
          color: rgb(94, 103, 114);

          code {
            background-color: $blue-color;
            padding: .4em;
          }
        }

        .tags {
          margin-top: 25px;
          a {
            border-bottom: none;
          }
          a:hover {
            color: #fff;
            border-bottom: none;
          }
        }

        a {
          color: $blue-color;
          border-bottom: 1px dashed $blue-color;
        }
        a:hover{
          text-decoration: none;
          border-bottom-style: solid;
        }

        .photo-wrapper {
          background-color: #f4f5f6;
          border-radius: .3em;
          padding:2em;
          margin-bottom: 1em;
          display: block; /* Adjust to fit the image */

          img {
            margin: auto;
            display: block; /* Prevents image spacing issues */
            max-width: 100%; /* Ensures responsiveness */
            border-radius: 6px; /* Optional rounded corners for the image */
            border: 1px solid rgba(0, 0, 0, 0.05); /* Light border */
          }
        }
      }

      .post-section {
        border-top: 1px solid #ccc;
        padding-top: 10px;
      }

      .post-recent {
        padding-bottom: 3em;

        .row {
          padding-top:0;
        }
      }
    }
  }
}

@media screen and (max-width: 40em) {
  .post-wrapper { 
    .post-content {
      padding: 20px !important;
    }

    .post-recent {
      padding: 20px !important;

      img {
        display: block;
      }
    }
  }
}
