ul.trigger {
	list-style-type:none;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		margin-left:10px;

		@media screen and (max-width: 40em) {
			display: block;
		}
	}
}

// mobile overwrite:
nav.site-nav {
	top:25px;
}
