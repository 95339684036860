.top-banner {
  background-image: url('/assets/images/default_post_thumbnail.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  padding: 3em 0;

  h1, p {
    color: #fff;
    //text-align: center;
  }

  .basic-header {
    text-align: center;
    padding: 0 3em;
  }
}
