/* medium up: */
@media screen and (min-width: 40em) {

	.row {
		display: inline-block;
		width:100%;
		clear:both;
		padding:1em 0;

		.columns {
			padding:0 1em;
			box-sizing: border-box;
		}

		.medium-2,.medium-3, .medium-4, .medium-6, .medium-8, .medium-9, .medium-10, .medium-11 {
			float: left;
			box-sizing: border-box;
		}

		.medium-2 { width: 16.66666666666667%; }
		.medium-3 { width: 25%; }
		.medium-4 { width: 33.33333333333333%; }
		.medium-5 { width: 41.66666666666667%; }
		.medium-6 { width: 50%; }
		.medium-7 { width: 58.33333333333333%; }
		.medium-8 { width: 66.66666666666667%; }
		.medium-9 { width: 75%; }
		.medium-10 { width: 83.33333333333333%; }
		.medium-11 { width: 91.66666666666667%; }

		.medium-offset-1 {
			margin-left:8.33333333333333%;
		}
		.medium-offset-2 {
			margin-left:16.66666666666667%;
		}
	}

}