.project {
	h2, p {
		color: $text-color;
	}

	h2 {
		line-height: 1.25;
	}

	ul.meta {
		color: $grey-color;
	}
}

a.project:hover {
	h2 {
		color: $blue-color;
	}
}

.post-image {
	text-align: center;
	background-color:#efefef;
	margin: 1em 0;
	border-top:1px solid #ccc;
}