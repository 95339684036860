/* tag list */
ul.tags {
	list-style-type: none;
	margin:1em 0;
	padding-bottom:0;

	li {
		display:inline-block;
		margin: 0 .25em .5em 0;

		a {
			display:block;
			padding:.25em .5em;
			background-color:$grey-color-light;
			color: $blue-color;
			font-size:.8em;
		}
		a:hover {
			background-color:$blue-color;
			color: #fff;
			text-decoration:none;
		}
		a.active {
			background-color: $blue-color;
			color: #fff;
			cursor: auto;
		}
	}
}

ul.skills {
	margin-bottom: 1em;
}
