.print-only {
  display: none;
}

@media print {
  header, footer, .sidebar, .hide-print {
    display: none;
  }

  p.header1 {
    font-size: 1.75em !important;
  }

  p, li {
    font-size: 14px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 0 !important;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 0 !important;
  }

  .print-only {
    display: block;
  }
}
