h2.meta {
	border-bottom:1px solid $grey-color-light;
	font-size:1.1em;
	color:$grey-color;
	font-weight: 300 !important;
	text-transform: uppercase;
	padding-bottom:.5em;
}

ul.related, ul.updates, ul.links {
	list-style-type: none;
	margin: 0 0 2em .5em;
	padding: 0;

	li:before {
		content: "-";
		text-indent: -5px;
		margin-right:10px;
	}
}


.jwt {
	font-size: 12px;
}

.post {
	table {
		td, th {
			padding: 10px;
		}
	}

	p, li {
		font-size: 1.1em;
		line-height: 1.75em;
		color: rgb(94, 103, 114);
	}
}
