.post-list {
	.post-row {
		li {
			.medium-4 {
				padding-left: 0 !important;
			}
		}
	}

	h2 {
		margin-bottom: 0;	//was 15px, moved to .post-meta
	}

	.post-meta {
		margin:5px 0 10px 0;
		display: block;
	}
}

.post-container {
	a {
		display: flex;
		padding-bottom: 20px;

		h2 {
			line-height: 1.4em;

			.external-icon {
				margin-left: 5px;
				height: 20px;
				width: 20px;
			}
		}

		p {
			color: #424242;
		}

		time {
			margin: 3px 0;
			color: #999;
			display: block;
		}
	}

	a:hover {
		text-decoration: none;
		h2 {
			text-decoration: underline !important;
		}
	}
}

.column-left {
  width: 40%;
	min-height: 120px;
  background-image: url('/assets/images/default_thumbnail.jpg');
  background-size: 100%;
  background-position: top;
	background-repeat: no-repeat;
}

.column-right {
  width: 60%;
  padding-left: 20px;
}

@media screen and (max-width: 40em) {
	.post-container {
		a {
			display: block;
		}
	}
	.column-left, .column-right {
		width: 100%;
		overflow: hidden;
	}
	.column-right {
		padding: 10px;
	}
}

// code blocks

code:not([class]) {
  background-color: black;
	color: white;
	padding: 5px;
	font-size: .8em;
}
