h1 {
	font-size: 3em;

	.small {
		display: block;
		color: #fff;
		font-size: .5em;
	}
}

h3 {
	font-weight: 700 !important;
}

@media screen and (max-width: 40em) {
	h1 {
		font-size: 2em;
	}
}
