.linktree {
  h1, p, a {
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
  }

  img {
    max-width: 50%;
  }

  a {
    padding: 10px 0;
    display: block;
    border: 1px solid #000;
    background-color: rgba(255, 255, 255, 0.9);
  }

  a:hover {
    background-color: #fff;
  }

  hr {
    margin-bottom: 15px;
  }
}