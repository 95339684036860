.sidebar {
	h2 {
		margin-bottom:0;
		font-size:1em;
		font-weight:bold;
		color:$grey-color-dark;
	}
	
	ul.posts {
		list-style-type: none;
		margin: 0 0 1em 0;
		padding: 0;
		li {
			border-bottom:1px solid $grey-color-light;
			
			a {
				display: block;
				padding:.5em 1em .5em 0;
			}
		}
	}

	#dailyphoto, #recentvideo {
		border-top: 1px solid $grey-color-light;
		padding: 15px 0;

		.meta {
			border: none;
		}
		a {
			display: block;
			margin-bottom: 10;
			img {
				border:1px solid $grey-color-light;
				padding:3px;
			}
		}
		a:hover img{
			border-color:$blue-color;
		}
	}
}

@media screen and (max-width: 40em) {
	.sidebar {
		margin-top:2em;
		padding-top:1em;
		border-top:1px solid $grey-color-dark
	}
}