.search{
    #searchbox {
        width:70%;
        font-size:16px;
        padding: .5em 1em;
    }

    #searchbutton {
        width:20%;
        min-width: 125px;
        display: inline-block;
        border: none;
        padding: .6rem 1rem;
        margin: 0;
        text-decoration: none;
        background: $brand-color;
        color: #ffffff;
        font-size: 1rem;
        cursor: pointer;
        text-align: center;
        transition: background 250ms ease-in-out, 
                    transform 150ms ease;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    .logo{
        padding:.25em 0;
    }
    
    #hits {
        padding-top:1em;
    }
}